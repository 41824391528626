var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.tituloTabla)+" ")]),_c('v-divider',{staticClass:"mx-3",attrs:{"inset":"","vertical":""}}),_vm._l((_vm.editPerfil.permiso),function(permiso){return _c('div',{key:permiso},[(_vm.accionAgregar && (permiso == 1 || permiso == 0))?_c('span',{staticStyle:{"display":"contents"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.agregar}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" Agregar ")],1),_c('v-divider',{staticClass:"mx-3",attrs:{"inset":"","vertical":""}})],1):_vm._e()])}),(_vm.exportar)?_c('div',[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","color":"success"},on:{"click":_vm.exportarXLS}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-file-excel")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","color":"error"},on:{"click":_vm.exportarPDF}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar:","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"items":_vm.body,"search":_vm.search,"footer-props":{
        showFirstLastPage: true, 
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-text': 'N° Paginas',
        'items-per-page-all-text':'Todos',
        pageText: '{1} de {2}',
        }},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_vm._v(" "+_vm._s(++index)+" ")]}},{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return _vm._l((_vm.editPerfil.permiso),function(permiso){return _c('span',{key:permiso},[(permiso == 2 || permiso == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.actualizar(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(permiso == 3 || permiso == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)})}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.recargar}},[_vm._v(" Recargar ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }