<template>
    <div>
        <v-card>
            <v-card-title>
                <v-toolbar-title>
                    {{tituloTabla}}
                </v-toolbar-title>
                <v-divider class="mx-3" inset vertical></v-divider>
                <div v-for="permiso in editPerfil.permiso" :key="permiso">
                    <span v-if="accionAgregar && (permiso == 1 || permiso == 0)" style="display:contents;">
                        <v-btn color="primary" class="" @click="agregar">
                            <v-icon>mdi-content-save-outline</v-icon> Agregar
                        </v-btn>
                        <v-divider class="mx-3" inset vertical></v-divider>
                    </span>
                </div>
                <div v-if="exportar">
                    <v-btn @click="exportarXLS" class="mx-1" icon color="success">
                        <v-icon large>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-btn @click="exportarPDF" class="mx-1" icon color="error">
                        <v-icon large>mdi-file-pdf-box</v-icon>
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar:" outlined hide-details
                    clearable></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="body" :search="search" :footer-props="{
            showFirstLastPage: true, 
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-text': 'N° Paginas',
            'items-per-page-all-text':'Todos',
            pageText: '{1} de {2}',
            }" class="elevation-1">
                <!-- <template v-slot:item.numero="{ item }"> -->
                <template v-slot:item.numero="{item, index}">
                    {{ ++index  }}
                </template>
                <template v-slot:item.acciones="{ item }">
                    <span v-for="permiso in editPerfil.permiso" :key="permiso">
                        <v-btn v-if="permiso == 2 || permiso == 0" small class="ma-1" color="blue"
                            @click="actualizar(item)">
                            <v-icon small color="white">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn v-if="permiso == 3 || permiso == 0" small class="ma-1" color="error"
                            @click="eliminar(item)">
                            <v-icon small color="white">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="recargar">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';
    import XLSX from 'xlsx'

    export default {
        name: "tabla",
        props: ['header', 'body', 'tituloTabla', 'accionAgregar', 'exportar'],

        data: () => ({
            search: '',
        }),
        created() {
            this.editPerfil = JSON.parse(this.usuario)
        },
        computed: {
            ...mapState(["usuario"]),
        },
        methods: {

            recargar() {
                this.$emit('recargar');
            },

            agregar() {
                this.$emit('agregar', true);
            },

            actualizar(item) {
                this.$emit('actualizar', item);
            },

            eliminar(item) {
                this.$emit('eliminar', item);
            },

            exportarXLS() {
                let columns = [];
                // let ws_name = this.tituloTabla;
                let wb = XLSX.utils.book_new();
                /* make worksheet */
                this.header.forEach(element => {
                    if (element.text != 'Acciones') {
                        columns.push(element.value);
                    }
                });

                let body = []
                let i = 0;

                this.body.forEach(element => {
                    if (this.search != '') {
                        Object.keys(element).some(key => {
                            if ((element[key] != null) && (element[key]) && (element[key].toString()
                                    .toLowerCase().includes(this.search.toString().toLowerCase()))) {
                                element.numero = ++i;
                                delete element.Uid;
                                body.push(element);
                            }
                        });
                    } else {
                        this.body.forEach(element => {
                            element.numero = ++i;
                            delete element.Uid;
                            body.push(element)
                        })
                    }
                })

                const dataArr = new Set(body);
                let result = [...dataArr];

                let ws = XLSX.utils.json_to_sheet(result, {
                    header: columns
                });
                if (!wb.Props) wb.Props = {};
                wb.Props.Title = "Insert Title Here";
                XLSX.utils.book_append_sheet(wb, ws, this.tituloTabla);
                XLSX.writeFile(wb, `${this.tituloTabla}.xlsx`)
            },

            exportarPDF() {
                let columns = [];
                let body = []
                let bodyy = []
                let i = 0;

                this.body.forEach(element => {
                    if (this.search != '') {
                        Object.keys(element).some(key => {
                            if ((element[key] != null) && (element[key]) && (element[key].toString()
                                    .toLowerCase().includes(this.search.toString().toLowerCase()))) {
                                element.numero = ++i;
                                body.push(element);
                            }
                        });
                    } else {
                        element.Uid = ++i;
                        body.push(element)
                    }
                })

                this.header.forEach(element => {
                    if (element.text != 'Acciones') {
                        columns.push(element.value)
                    }
                });

                const doc = new jsPDF();
                doc.text(this.tituloTabla, 15, 10);

                let result = [...new Set(body)];

                result.forEach(element => bodyy.push(Object.values(element)))

                autoTable(doc, {
                    head: [columns],
                    body: bodyy,
                });
                doc.save(`${this.tituloTabla}.pdf`);
            }
        }
    }
</script>